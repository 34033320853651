import React, { useState, useRef, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import exifr from 'exifr';
import GIFEncoder from 'gif-encoder-2';
import './App.css';

import Header from './components/Header';
import Description from './components/Description';

const App = () => {

  const GIF_WIDTH = 1280;
  const GIF_HEIGHT = 720;

  //const [selectedFiles, setSelectedFiles] = useState([]);
  const [stage, setStage] = useState('initial');
  const [generatedGif, setGeneratedGif] = useState(null);
  const [generatedGifName, setGeneratedGifName] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState([]);
  const [infoMessage2, setInfoMessage2] = useState('');
  const fileInputRef = useRef(null);
  const arrowRef = useRef(null);
  
  // useEffect(() => {
  //   setErrorMessage('frefv3f34f34f34f43f4f443g\ngbn453b45bbn45b4534v34v45b65jh4545g4554b45g34453');
  // }, []);
  
  useEffect(() => {
    // Check if session_id exists in local storage

    setInfoMessage([["https://t.me/KreativnoeAgentstvo"]])

    let sessionId = localStorage.getItem('session_id');
    if (!sessionId) {
      // If not, generate a new one and store it
      sessionId = uuidv4();
      localStorage.setItem('session_id', sessionId);
      console.log('New session_id generated:', sessionId);
    } else {
      console.log('Existing session_id:', sessionId);
    }

    // Make a non-blocking fetch request to the server
    const sendSessionId = async () => {
      try {
        await fetch('/session_id_endpoint', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ session_id: sessionId })
        });
      } catch (error) {
        console.error('Error sending session_id to server:', error);
      }
    };

    sendSessionId();
  }, []);

  const sortFilesByCreationDate = async (files) => {
    const filesWithDate = await Promise.all(files.map(async (file) => {
      const metadata = await exifr.parse(file);
      const creationDate = metadata?.CreateDate || new Date();
      return { file, creationDate };
    }));

    filesWithDate.sort((a, b) => a.creationDate.getTime() - b.creationDate.getTime());
    return filesWithDate.map(item => item.file);
  };

  // const sortFilesByName = (files) => {
  //   return files.sort((a, b) => a.name.localeCompare(b.name));
  // };

  const handleFileUpload = async (event) => {
    try {
      let files = Array.from(event.target.files);
      if (files.length === 0) {
        setErrorMessage('Выбрано 0 файлов.');
        return;
      }

      files = await sortFilesByCreationDate(files);

      const infoMetadataList = [];
      const createDateList = [];

      // for (const file of files) {
      //   const metadata = await exifr.parse(file);
      //   if (metadata) {
      //     delete metadata['39594']; // Exclude the 39594 field
      //     const metadataEntries = Object.entries(metadata).map(([key, value]) => `${key}: ${value}`);
      //     infoMetadataList.push(metadataEntries);

      //     const { CreateDate } = metadata;
      //     if (CreateDate && typeof CreateDate.getHours === 'function') {
      //       createDateList.push(`${CreateDate.getHours().toString().padStart(2, '0')}${CreateDate.getMinutes().toString().padStart(2, '0')}${CreateDate.getSeconds().toString().padStart(2, '0')}${CreateDate.getDate().toString().padStart(2, '0')}`);
      //     } else {
      //       createDateList.push('None');
      //     }
      //   } else {
      //     infoMetadataList.push(['No metadata available']);
      //     createDateList.push('None');
      //   }
      // }

      for (const file of files) {
        const metadata = await exifr.parse(file);
        //let dateExp = 'NonexNone';
        let dateExp = 'None';
        if (metadata) {
          const { ModifyDate, CreateDate, DateTimeOriginal, ExposureTime} = metadata;
          infoMetadataList.push([`ModifyDate: ${ModifyDate}`, `CreateDate: ${CreateDate}`, `DateTimeOriginal: ${DateTimeOriginal}`, `ExposureTime: ${ExposureTime}`]);
          if (CreateDate && typeof CreateDate.getHours === 'function') {
            dateExp = `${CreateDate.getHours().toString().padStart(2, '0')}${CreateDate.getMinutes().toString().padStart(2, '0')}${CreateDate.getSeconds().toString().padStart(2, '0')}${CreateDate.getDate().toString().padStart(2, '0')}`;
          } else {
            dateExp = 'None';
          }
          
          // if (typeof ExposureTime === 'number' && !isNaN(ExposureTime)) {
          //   const formattedExposureTime = ExposureTime.toFixed(4).toString().replace(/\./g, '-');
          //   dateExp += `x${formattedExposureTime}`;
          // } else {
          //   dateExp += `xNone`;
          // }
        } else {
          infoMetadataList.push([`ModifyDate: None`, `CreateDate: None`, `DateTimeOriginal: None`, `ExposureTime: None`]);
        }
        createDateList.push(dateExp);
      }
  
      const imageUrls = files.map(file => URL.createObjectURL(file));
      //setSelectedFiles(imageUrls);
      //setInfoMessage(infoMetadataList)
      //setGeneratedGifName(createDateList.join('_'));
      setGeneratedGifName(createDateList.length > 0 && createDateList[0] !== 'None' ? createDateList[0] : '');
      generateGif(imageUrls);
    } catch (error) {
      setErrorMessage('Error uploading files.', error.message);
      console.error('Error uploading files:', error);
    }
  };
  
  const preprocessImages = async (imageUrls, callback) => {
    try {

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = GIF_WIDTH;
      canvas.height = GIF_HEIGHT;
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, GIF_WIDTH, GIF_HEIGHT);

      const promises = imageUrls.map(async (url) => {
        const img = new Image();
        img.src = url;

        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        let { width, height } = img;
        if (width > GIF_WIDTH || height > GIF_HEIGHT) {
          const scale = Math.min(GIF_WIDTH / width, GIF_HEIGHT / height);
          width = Math.floor(width * scale);
          height = Math.floor(height * scale);
        }
        // Center the image
        const xOffset = (GIF_WIDTH - width) / 2;
        const yOffset = (GIF_HEIGHT - height) / 2;

        ctx.drawImage(img, xOffset, yOffset, width, height);
        return canvas.toDataURL('image/jpeg', 0.5);
      });

      const processedImages = await Promise.all(promises);
      callback(processedImages);

    } catch (error) {
      setErrorMessage('Error preprocessing images.' + error.message);
      console.error('Error preprocessing images:', error);
    }
  };

  const generateGif = async (imageUrls) => {
    try {
      setStage('generating');

      const images = await Promise.all(imageUrls.map(imageUrl => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      }));

      let horizontalCount = 0;
      let verticalCount = 0;
      for (const img of images) {
        const { width, height } = img;
        if (height > width) {
          verticalCount += 1;
        } else {
          horizontalCount += 1;
        }
      }

      let gifWidth = GIF_WIDTH;
      let gifHeight = GIF_HEIGHT;
      let maxWidth = 0;
      let maxHeight = 0;
      const canvasDimensions = new Map();

      if (verticalCount > horizontalCount) {
        for (let ii = 0; ii < images.length; ii++) {
          const img = images[ii];
          const { width, height } = img;
      
          if (height > gifHeight) {
            const scale = gifHeight / height;
            canvasDimensions.set(ii, [Math.floor(width * scale), gifHeight]);
          } else {
            canvasDimensions.set(ii, [width, height]);
          }
      
          if (height - width >= -0.1 && canvasDimensions.get(ii)[0] > maxWidth) {
            maxWidth = canvasDimensions.get(ii)[0];
          }
        }

        if (maxWidth <= 1.1) {
          maxWidth = gifWidth;
        }

        for (let [ii, [width, height]] of canvasDimensions.entries()) {
          if (width > maxWidth) {
            const scale = maxWidth / width;
            canvasDimensions.set(ii, [maxWidth, Math.floor(height * scale)]);
          }

          if (width <= 1.1) {
            canvasDimensions.set(ii, [maxWidth, height]);
          }
          if (height <= 1.1) {
            canvasDimensions.set(ii, [width, gifHeight]);
          }
        }
        gifWidth = Math.floor(maxWidth);

      } else {
        for (let ii = 0; ii < images.length; ii++) {
          const img = images[ii];
          const { width, height } = img;
  
          if (width > gifWidth) {
            const scale = gifWidth / width;
            canvasDimensions.set(ii, [gifWidth, Math.floor(height * scale)]);
          } else {
            canvasDimensions.set(ii, [width, height]);
          }
  
          if (width - height >= -0.1 && canvasDimensions.get(ii)[1] > maxHeight) {
            maxHeight = canvasDimensions.get(ii)[1];
          }
        }
  
        if (maxHeight <= 1.1) {
          maxHeight = gifHeight;
        }

        for (let [ii, [width, height]] of canvasDimensions.entries()) {
          if (height > maxHeight) {
            const scale = maxHeight / height;
            canvasDimensions.set(ii, [Math.floor(width * scale), maxHeight]);
          }

          if (width <= 1.1) {
            canvasDimensions.set(ii, [gifWidth, height]);
          }
          if (height <= 1.1) {
            canvasDimensions.set(ii, [width, maxHeight]);
          }
        }
        gifHeight = Math.floor(maxHeight);
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = gifWidth;
      canvas.height = gifHeight;
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, gifWidth, gifHeight);

      const gifEncoder = new GIFEncoder(gifWidth, gifHeight, 'neuquant', false);
      gifEncoder.setDelay(160*1.2); // 160ms per frame
      gifEncoder.setRepeat(0); // Loop the GIF 3 times
      gifEncoder.setQuality(1); // Set highest quality (more colors preserved)
      //gifEncoder.setThreshold(90);
      gifEncoder.start();

      //const repeatedImageUrls = [...imageUrls, ...imageUrls, ...imageUrls];
      for (let [ii, [width, height]] of canvasDimensions.entries()) {
        const img = images[ii];

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Center the image
        const xOffset = (canvas.width - width) / 2;
        const yOffset = (canvas.height - height) / 2;

        ctx.drawImage(img, xOffset, yOffset, width, height);
      
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        gifEncoder.addFrame(imageData);
      }

      gifEncoder.finish();

      const binaryGif = gifEncoder.out.getData();
      const gifBlob = new Blob([binaryGif], { type: 'image/gif' });
      const gifUrl = URL.createObjectURL(gifBlob);

      setGeneratedGif(gifUrl);
      setStage('complete');

    } catch (error) {
      setErrorMessage('Error generating GIF.' + error.message);
      setStage('initial');
      console.error('Error generating GIF:', error);
    }
  };

  const downloadGif = () => {
    if (generatedGif && stage === 'complete') {
      try {
        const link = document.createElement('a');
        link.href = generatedGif;
        link.download = `animareska_${generatedGifName}.gif`;
	      document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Animate the arrow
        if (arrowRef.current) {
          arrowRef.current.classList.add('celebrate');
          setTimeout(() => {
            arrowRef.current.classList.remove('celebrate');
          }, 700); // Match this duration to your animation duration
        }

        // Reset to initial state
        //setSelectedFiles([]);
        //setGeneratedGif(null);
        //setStage('initial');
      } catch (error) {
        setErrorMessage('Error downloading GIF.', error.message);
        console.error('Error downloading GIF:', error);
      }
    }
  };

  const openFileDialog = () => {
    try {
      fileInputRef.current.click();
    } catch (error) {
      setErrorMessage('Error opening file dialog.', error.message);
      console.error('Error opening file dialog:', error);
    }
  };

  const renderMetadata = (metadataArray, index) => {
    return (
      <div key={index}>
        {/* <p>Photo {index + 1}</p> */}
        {metadataArray.map((msg, subIndex) => (
          <p key={subIndex}>{msg}</p>
        ))}
      </div>
    );
  };

  return (
    <div className="App">
        <Header/>
        <Description/>
        <div className="container-wrapper">
            <div className="container">
              <div className="create-square">
                {stage === 'initial' && (
                  <>
                    <button className="create-btn scale-appear" onClick={openFileDialog}>Создать Анимареску</button>
                    <input type="file" multiple accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload}/> 
                  </>
                )}
                {stage === 'generating' && (
                  <div className="generating-text-container">
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </div>
                )}
                {stage === 'complete' && (
                  <img src={generatedGif} alt="Generated GIF" className="scaled-gif" />
                )}
              </div>
              <div className="download-btn" onClick={downloadGif}>
                <div className="download-btn-text">Скачать</div>
                <svg ref={arrowRef} className="svg-arrow" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M 14.820312 22.003906 C 14.078125 22.636719 13.351562 23.257812 12.597656 23.898438 C 8.503906 20.40625 4.410156 16.910156 0.316406 13.417969 C 0.320312 13.398438 0.324219 13.378906 0.328125 13.363281 C 2.578125 13.363281 4.828125 13.363281 7.101562 13.363281 C 7.101562 8.980469 7.101562 4.636719 7.101562 0.273438 C 10.773438 0.273438 14.417969 0.273438 18.097656 0.273438 C 18.097656 0.386719 18.097656 0.496094 18.097656 0.601562 C 18.097656 4.738281 18.097656 8.871094 18.097656 13.003906 C 18.097656 13.351562 18.097656 13.351562 18.421875 13.351562 C 20.488281 13.351562 22.554688 13.351562 24.621094 13.351562 C 24.699219 13.351562 24.777344 13.351562 24.859375 13.351562 C 24.867188 13.371094 24.875 13.390625 24.886719 13.414062 C 21.535156 16.273438 18.183594 19.132812 14.820312 22.003906 Z M 14.820312 22.003906 "/>
                </svg>
              </div>
            </div>
        </div>
        {(errorMessage || true) && (
          <div className="error-message">
            <p>{errorMessage}</p>
            <a href="https://t.me/KreativnoeAgentstvo" target="_blank" rel="noopener noreferrer">
              t.me/KreativnoeAgentstvo
            </a>
            <p style={{ marginBottom: '0vh' }}>Закажи анимареску</p>
            <p style={{ marginBottom: '0vh', marginTop: '0.5vh' }}>animareska@gmail.com</p>
            {/* {Array.isArray(infoMessage) && infoMessage.map((group, index) => (
              renderMetadata(group, index)
            ))} */}
          </div>
        )}

    </div>
  );
};

export default App;
