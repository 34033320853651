import React, { useState, useEffect} from 'react';

const Description = () => {

    const useDetectiOS = () => {
        const [isIOS, setIsIOS] = useState(false);
      
        useEffect(() => {
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const platform = navigator.platform;
      
          // Detect iOS using user agent and platform checks
          const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
          const isiOSPlatform = /iPad|iPhone|iPod/.test(platform);
      
          // Fallback detection based on touch events (optional, for additional confidence)
          //const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
          
          // Combine checks
          setIsIOS(isiOS || isiOSPlatform/* || hasTouch*/);
        }, []);
      
        return isIOS;
    };

    //const isIOS = useDetectiOS();

    return (
    <div className="description-container">
        <span className="description-text-rest-lines">Генерация анимарески может занять некоторое время.</span>
        <span className="description-text-rest-lines">Чтобы создать следующую анимареску, обновите страницу.</span>
        <span>&nbsp;</span>
        <span className="description-text-rest-lines3">Мы не храним ваши фотографии.</span>
        <span className="description-text-rest-lines3">Gif генерируется на вашем устройстве.</span>
        {/* <span className="description-text-first-line">Загрузка gif-анимации может занять 2-3 минуты.</span> */}
        {/*
            (isIOS) && (
                <>
                <span className="description-text-rest-lines2">* к сожалению, кнопка "скачать" в данный момент не работает на iphone.</span>
                <span className="description-text-rest-lines2">Для загрузки анимации, используйте долгое нажатие на картинку,</span>
                <span className="description-text-rest-lines2">далее нужно выбрать "сохранить в галерею".</span>
                </>
            )
        */}
        {/* <span className="description-text-first-line">Реальный и анимационный мир</span>
        <span className="description-text-first-line">соединились воедино!</span>
        <span className="description-text-rest-lines">Вы когда-нибудь видели аттракцион,</span>
        <span className="description-text-rest-lines">где нужно просовывать голову</span>
        <span className="description-text-rest-lines">в прорези вырезанных фигур,</span>
        <span className="description-text-rest-lines">и фотографироваться? Это называется тантамареска. Анимареска - это почти то же самое, только</span>
        <span className="description-text-rest-lines">с анимацией. Собери мультфильм</span>
        <span className="description-text-rest-lines">со своим участием!</span> */}
    </div>);
};

export default Description;
